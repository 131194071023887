import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
//import Form from 'react-bootstrap/Form';
import ReactImageZoom from 'react-image-zoom';
import ReactLoading from 'react-loading';

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            products: [],
            selectedFilters: []
        };
    }

    componentDidMount() {
        let type = window.location.pathname.replaceAll(/\W/g, '');

        if (!type) {
            type = "aneis"
        }

        fetch(`${process.env.REACT_APP_BACKEND_API}/section/${type}`, {
            method: "GET", mode: 'cors', headers: {
                "Content-type": "application/json",
                "x-functions-key": `${process.env.REACT_APP_API_SECTION_KEY}`
            }
        })
            .then((response) => response.json())
            .then((data) => this.setState(
                {
                    filters: data.filters,
                    products: data.products
                }))
            .catch((error) => console.log(error));

        window.history.replaceState({}, document.title)
    }

    handleFilterChange = (filterProperty, filterValue, checked) => {
        const { products } = this.state;
        var selectedFilters = [...this.state.selectedFilters];

        const filter = selectedFilters.find(({ property }) => property === filterProperty);

        if (filter !== undefined) {
            // Remove filter value
            if (!checked) {
                const index = filter.values.indexOf(filterValue);
                filter.values.splice(index, 1);
            } else {
                // Add new filter value
                filter.values.push(filterValue);
            }
        } else {
            const f = {
                property: filterProperty,
                values: [filterValue]
            }
            selectedFilters.push(f)
        }

        // Only filters with values to compare
        selectedFilters = selectedFilters.filter(f => f.values.length > 0);

        var filteredProducts = products;

        if (selectedFilters.length > 0) {
            filteredProducts = products.filter((product) => {
                return selectedFilters.every((filter) => {
                    return filter.values.includes(product.properties[filter.property])
                })
            });
        }

        this.setState({
            selectedFilters: selectedFilters,
            filteredProducts: filteredProducts
        });
    }

    render() {
        const { products, filteredProducts } = this.state;

        const productList = filteredProducts ? filteredProducts : products;

        return (
            <section className="women-banner spad">
                <Container fluid>
                    <Row className="row-cols-3=auto">
                        {/*
                        <Col className="filter-section">
                            <hr></hr>
                            <strong>Filtros</strong>
                            <hr></hr>
                            <Form>
                                {this.state.filters.map((filter, i) => (
                                    <React.Fragment key={i}>
                                        <div className="filter-form">
                                            <strong>{filter.name}</strong>
                                        </div>

                                        <div className="mb-3 filter-form">
                                            {filter.values.map((value) => (
                                                <Form.Check key={filter.property + '_' + value} type="checkbox" id={filter.property} label={value} onChange={(event) => this.handleFilterChange(filter.property, value, event.target.checked)} />
                                            ))}
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                ))}
                            </Form>
                        </Col>
                        */}

                        <Col>
                            <div className="product-card">
                                {productList.length ? productList.map((product) => (
                                    <div key={product.id} className="product-item">
                                        <div className="pi-pic">

                                            <ReactImageZoom
                                                img={`${process.env.REACT_APP_IMAGE_URL}/${product.code}.jpeg`}
                                                zoomPosition="original"
                                            />
                                        </div>
                                        <div className="pi-text">
                                            <div className="catagory-name">{product.category}</div>
                                            <h5>{product.name}</h5>
                                            <div className="product-price">
                                                R$ {product.price.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    <ReactLoading id="loading" type={'bubbles'} color={'#000000'} height={50} width={50} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Section;
