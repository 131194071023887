import './App.css';
import React from 'react';

//import Home from './home/Home';
import Section from './Section';
import Search from './Search';

import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import { Container, Row, Col, Nav } from 'react-bootstrap';

function App() {

  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    localStorage.setItem("searchQuery", e.target.value);
  };

  useEffect(() => {
    setSearchQuery(localStorage.getItem("searchQuery"));
  }, []);

  return (
    <div className="App">
      <header className="header-section">
        <Container>
          <div className="inner-header">
            <Row>
              {/*BRAND*/}
              <Col lg={4} md={4}>
                <div className="logo">
                  <Row>
                    <Col>
                      <img src="logo_v.png" alt="Adornos da Val"></img>
                    </Col>
                    <div className="brand-text">
                      <div className="brand-primary-text">
                        ADORNOS DA VAL
                      </div>
                      <div className="brand-second-text">
                        PRATAS E SEMIJÓIAS
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>

              {/*SEARCH*/}
              <div className="col-lg-5 col-md-5 search-block">
                <div className="advanced-search">
                  <div className="input-group">
                    <form action="/search" method="GET">
                      <input
                        name="q"
                        type="text"
                        placeholder="O que você procura?"
                        value={searchQuery}
                        onChange={handleChange}
                      />
                      <button type="submit"><i className="ti-search"></i></button>
                    </form>
                  </div>
                </div>
              </div>
            </Row>

          </div>
        </Container>
      </header>

      <Router forceRefresh={true}>
        <header>
          <Nav.Item>
            <Container fluid>
              <Nav className="nav-menu mobile-menu">
                <li><NavLink to="/" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Home</NavLink></li>
                <li><NavLink to="/aneis" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Anéis</NavLink></li>
                <li><NavLink to="/brincos" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Brincos</NavLink></li>
                <li><NavLink to="/pulseiras" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Pulseiras</NavLink></li>
                <li><NavLink to="/braceletes" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Braceletes</NavLink></li>
                <li><NavLink to="/gargantilhas" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Gargantilhas</NavLink></li>
              </Nav>
              <div id="mobile-menu-wrap"></div>
            </Container>
          </Nav.Item>
        </header>


        <Routes>          
          <Route exact path="/" element={<Section />} />
          <Route path="/aneis" element={<Section />} />
          <Route path="/brincos" element={<Section />} />
          <Route path="/search" element={<Search />} />
        </Routes>
      </Router>

      <footer className="footer-section">
        <Container fluid="md">
          <Row className="justify-content-md-center">
            {/*
            <div className="col-lg-3">
              <div className="footer-left">
                <div className="footer-logo">
                  <a href="/">Adornos da Val</a>
                </div>
                <ul>
                   <li>Address: 11-11 Road 11 22 22</li>
                  <li>Phone: +00 00.000.000</li>
                  <li>Email: adornosdaval@gmail.com</li>
                  <li>Instagram: @adornosdaval</li>
                </ul>
                <div className="footer-social">
                  <a href="/"><i className="fa fa-instagram"></i></a>
                  <a href="/"><i className="fa fa-facebook"></i></a>
                  <a href="/"><i className="fa fa-twitter"></i></a>
                  <a href="/"><i className="fa fa-pinterest"></i></a>
                </div>
              </div>
            </div>
          */}

            {/* 
            <div className="col-lg-2">
              <div className="footer-widget">
                <span style={{ color: "gray" }}>
                  <i className="fa fa-smile-o fa-3x"></i>
                </span>
                <a class="footer-link" href="/">Sobre nós</a>
              </div>
            </div>
            */}

            {/*
            <div className="col-lg-2">
              <div className="footer-widget">
                <span style={{ color: "gray" }}>
                  <i className="fa fa-envelope fa-3x"></i>
                </span>
                <a class="footer-link" href="/">adornosdaval@gmail.com</a>
              </div>
            </div>
            */}

            <div className="col-lg-2">
              <div className="footer-widget">
                <span style={{ color: "gray" }}>
                  <i className="fa fa-instagram fa-3x"></i>
                </span>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/adornos_da_val">Siga-nos</a>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="footer-widget">
                <span style={{ color: "gray" }}>
                  <i className="fa fa-whatsapp fa-3x"></i>
                </span>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://wa.me/5511979997364">Whats</a>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="footer-widget">
                <span style={{ color: "gray" }}>
                  <i className="fa fa-exchange fa-3x"></i>
                </span>
                <a className="footer-link" href="/">Garantias e Trocas</a>
              </div>
            </div>

            {/*
              <div className="col-lg-2">
              <div className="footer-widget">
                <h5>My Account</h5>
                <ul>
                  <li><a href="/">My Account</a></li>
                  <li><a href="/">Contact</a></li>
                  <li><a href="/">Shopping Cart</a></li>
                  <li><a href="/">Shop</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="newslatter-item">
                <h5>Join Our Newsletter Now</h5>
                <p>Get E-mail updates about our latest shop and special offers.</p>
                <form action="#" className="subscribe-form">
                  <input type="text" placeholder="Enter Your Mail" />
                  <button type="button">Subscribe</button>
                </form>
              </div>
            </div>
            */}
          </Row>
        </Container>
        <div className="copyright-reserved">
          <Container fluid>
            <Row>
              <Col>
                <div className="copyright-text">
                &copy; <script>document.write(new Date().getFullYear());</script> Adornos da Val - Todos os direitos reservados
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </div>
  );
}

export default App;
