import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ReactImageZoom from 'react-image-zoom';
import ReactLoading from 'react-loading';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: []
        };
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search)
        const queryString = searchParams.get('q');

        fetch(`${process.env.REACT_APP_BACKEND_API}/search?q=${queryString}`, {
            method: "GET", mode: 'cors', headers: {
                "Content-type": "application/json",
                "x-functions-key": `${process.env.REACT_APP_API_SEARCH_KEY}`
            }
        })
            .then((response) => response.json())
            .then((data) => this.setState(
                {
                    total: data.meta.total,
                    products: data.products
                }))
            .catch((error) => console.log(error));

        window.history.replaceState({}, document.title)
    }

    render() {
        const { products, total } = this.state;

        const productList = products;

        return (
            <section className="women-banner spad">
                <Container fluid>
                    <Row className="row-cols-3=auto">
                        <Col>
                            <div className="product-card">
                                {productList.length ? productList.map((product) => (
                                    <div key={product.id} className="product-item">
                                        <div className="pi-pic">

                                            <ReactImageZoom
                                                img={`${process.env.REACT_APP_IMAGE_URL}/${product.code}.jpeg`}
                                                zoomPosition="original"
                                            />
                                        </div>
                                        <div className="pi-text">
                                            <div className="catagory-name">{product.category}</div>
                                            <h5>{product.name}</h5>
                                            <div className="product-price">
                                                R$ {product.price.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    <ReactLoading id="loading" type={'bubbles'} color={'#000000'} height={50} width={50} />
                                }

                                {total === 0 && (
                                    document.getElementById('loading').style.display = 'none',
                                    <div class="notfound"><p><h3>Ops, não encontramos...!</h3></p></div>
                                )}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Search;
